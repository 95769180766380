
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import { Box, Button, Grid, GridItem, Text } from '@chakra-ui/react';
import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';
import { getHost } from 'utils/getHost';
import EmptyContent from '../../public/images/empty-content.svg';
import { ErrorPage as ErrorPageModel } from '../models';
interface ErrorPageProps {
    code?: number;
}
export default function ErrorPage({ code = 404 }: ErrorPageProps) {
    const router = useRouter();
    const [errorpageData, setErrorPageData] = useState<ErrorPageModel>();
    const [isLoaded, setIsLoaded] = useState(false);
    // It is necessary to have the error page 500 details in the event of Kontent.ai not working properly, while the website host is working. Change according to what 500 error page should be showing by Kontent.ai.
    const pageTitle = 500;
    const title = 'There seems to be a problem on our end.';
    const description = "<p>Don't worry, it's not you, it's us.</p> <p>Sorry about that.</p>";
    useEffect(() => {
        const host = typeof window !== 'undefined' ? getHost(window) : '';
        const cookieBotFetchAPI = async () => {
            await fetch(`${host}/api/errorPageFetch?languageParameter=${router.locale ?? process.env.NEXT_PUBLIC_DEFAULT_LOCALE!}&errorCode=${code.toString()}`)
                .then(async (res) => {
                const jsonData = await res.json();
                if (jsonData.errorData) {
                    setErrorPageData(jsonData.errorData);
                }
            })
                .finally(() => {
                setIsLoaded(true);
            })
                .catch((error) => {
                throw error;
            });
        };
        cookieBotFetchAPI();
    }, []);
    return (<>
      {isLoaded && (<Box h='100vh'>
          <Grid mt='100px' gridTemplate='1fr 1fr' justifyContent={{ lg: 'center' }} gridAutoFlow={{ lg: 'row', xl: 'column' }} gridColumnGap='l' mx='l'>
            <GridItem>
              <Text textStyle='desktop.h2' fontSize='120px' color='cyan.ramboll' pb='xs'>
                {errorpageData?.elements.pageTitle.value ?? pageTitle}
              </Text>
              <Text textStyle='desktop.h5' fontWeight={700} pb='s'>
                {errorpageData?.elements.title.value ?? title}
              </Text>
              <Box textStyle='desktop.body.s' dangerouslySetInnerHTML={{ __html: errorpageData?.elements.description.value ?? description }}/>
              <Button variant='primary' size='medium' mt='m' mb='s' onClick={() => router.push('/')}>
                Ramboll home
              </Button>
            </GridItem>
            <GridItem mb='100px'>
              <EmptyContent />
            </GridItem>
          </Grid>
        </Box>)}
    </>);
}

    async function __Next_Translate__getStaticProps__191fae3bf46__(ctx) {
      
      return {
        
        
        props: {
          
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: '/error',
            loaderName: 'getStaticProps',
            loadLocaleFrom: __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
          }))
        }
      }
    }
    export { __Next_Translate__getStaticProps__191fae3bf46__ as getStaticProps }
  